import React from 'react'
import HeroModule from '../../lib/components/modules/hero'
import {
    preFooterTitle,
    cardsDataPrefooter,
    flexibleTextBlock,
    flexibleTextBlock2,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import FlexibleModule from '../../lib/components/modules/flexible'
import CodeBlock from '../../lib/components/basics/codeBlock'

const TryNowPage = () => {
    return (
        <div className='h-full bg-grey-98'>
            <div id='dupa' className='relative min-h-[calc(100vh-120px)] mt-10'>
                <HeroModule
                    pretitle='DOWNLOAD FREE'
                    title={<>Lenses Community&nbsp;Edition</>}
                    description={
                        <>
                            <p className='mb-4'>
                                A completely free instance of Lenses with a localhost Kafka —or
                                bring your own Kafka clusters.
                            </p>

                            <CodeBlock
                                text={`wget https://lenses.io/preview -O docker-compose.yml && ACCEPT_EULA=true docker compose up`}
                            />

                            <p className='my-4'>
                                You can visit Lenses HQ at{' '}
                                <a href='http://localhost:9991'>http://localhost:9991</a>. The
                                default credentials are admin/admin.
                            </p>
                        </>
                    }
                    isLight
                />

                <div className='bg-black' data-background='dark'>
                    <FlexibleModule
                        textBlocks={[flexibleTextBlock]}
                        childPosition='right'
                        image={flexibleTextBlock.image}
                    />
                </div>

                <div className='bg-grey-98' data-background='light'>
                    <FlexibleModule
                        textBlocks={[flexibleTextBlock2]}
                        childPosition='left'
                        image={flexibleTextBlock2.image}
                    />
                </div>

                <div className='bg-grey-20'>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default TryNowPage
