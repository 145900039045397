import React from 'react'
import CodeBlock from '../../lib/components/basics/codeBlock'

// // Video for flexibleMediaContent
import { DemoIcon, PricingPlansIcon } from '../../lib/components/basics/icons'

// // Image for flexible text blocks
import whatsInTheBox from '../../../resources/images/hanzo/try-now/whats-in-the-box.svg'
import loginScreen from '../../../resources/images/hanzo/try-now/login-screen.svg'

// FEATURES TITLE (Used in FixedTitle component)
export const featuresTitle = 'Let developers do their thing'

// PREFOOTER (Used in PreFooter component)
export const preFooterTitle = 'See how Lenses works for you in production'

// Text blocks for flexible module (Used in FlexibleModule component)
export const flexibleTextBlock = {
    title: 'What’s included?',
    description: (
        <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
            <li>Lenses 6 HQ — Community Edition</li>
            <li>Lenses 6 Agent</li>
            <li>Apache Kafka Broker</li>
            <li>Kafka Connect with Lenses Connector plugins installed</li>
            <li>Schema Registry</li>
            <li>Pre-loaded data generators to demo Lenses</li>
            <li>PostgreSQL</li>
        </ul>
    ),
    image: {
        imgSrc: whatsInTheBox,
        alt: 'what`s in the box',
    },
}

export const flexibleTextBlock2 = {
    title: 'Start using Lenses',
    description: (
        <>
            <ul className='list-disc list-outside !mx-0 !my-4 !pl-4'>
                <li>
                    <span>Run the docker command:</span>
                    <CodeBlock
                        text={`
wget https://lenses.io/preview -O docker-compose.yml
ACCEPT_EULA=true docker compose up
                    `}
                    />
                </li>
                <li>
                    Open the Lenses HQ at <a href='http://localhost:9991'>http://localhost:9991</a>
                </li>
                <li>
                    Log in with <i>admin / admin</i>
                </li>
                <li>Click on the first environment to try Lenses with synthetic data</li>
                <li>Add up to 2 Kafka clusters, and 2 users</li>
            </ul>
            <br />
            <p>Want to use Lenses with more than 2 Kafka clusters?</p>
        </>
    ),
    image: {
        imgSrc: loginScreen,
        alt: 'login screen',
    },
    ctas: [
        {
            href: '/request-demo',
            text: 'Get a demo',
        },
    ],
}

// CARDS DATA FOR PREFOOTER (Used in PreFooter component)
export const cardsDataPrefooter = [
    {
        title: 'Simple scalable plans',
        type: 'primary' as const,
        description: [],
        buttonText: 'See pricing',
        href: '/product/pricing/',
        icon: <PricingPlansIcon key='pricingIcon' />,
    },
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: 'secondary' as const,
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demo Icon' />,
    },
]
