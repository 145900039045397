// TextBlock.tsx
import React from 'react'
import { FlexibleTextBlockProps } from './types'
import * as myStyles from './styles.module.scss'
import ClickableArea from '../../basics/clickable-area/component'

const FlexibleTextBlock: React.FC<FlexibleTextBlockProps> = ({
    pretitle,
    title,
    description,
    ctas,
}) => {
    const styles: any = myStyles

    return (
        <div className={styles.c_flexibleTextBlock}>
            <div className={styles.content}>
                <div className={styles.textContent}>
                    {pretitle && <div className={styles.pretitle}>{pretitle}</div>}
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.description}>{description}</p>
                </div>
                <div className={styles.ctas}>
                    {ctas &&
                        ctas.map((cta, index) => (
                            <ClickableArea
                                key={index}
                                variant={cta.variant}
                                color={cta.color}
                                href={cta.href}
                                onClick={cta.onClick}>
                                {cta.text}
                            </ClickableArea>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default FlexibleTextBlock
